

import news from "./api_news.auto"

import role_route from "./api_role_route.auto"

import role from "./api_role.auto"

import wxamp_user_sign from "./api_wxamp_user_sign.auto"

import news_coupon_user_log from "./api_news_coupon_user_log.auto"

import wxamp_user_point from "./api_wxamp_user_point.auto"

import ali_oss from "./api_ali_oss.auto"

import area_china from "./api_area_china.auto"

import home_swiper from "./api_home_swiper.auto"

import news_area_class from "./api_news_area_class.auto"

import news_class from "./api_news_class.auto"

import user_roles from "./api_user_roles.auto"

import user from "./api_user.auto"

import wxamp from "./api_wxamp.auto"

import home from "./api_home.auto"

import news_coupon from "./api_news_coupon.auto"

import news_header from "./api_news_header.auto"

import news_user_log from "./api_news_user_log.auto"

import route from "./api_route.auto"

import dictionary from "./api_dictionary.auto"

import file from "./api_file.auto"

import home_news from "./api_home_news.auto"

import model from "./api_model.auto"

import wxamp_user from "./api_wxamp_user.auto"


export default {
   news,
   role_route,
   role,
   wxamp_user_sign,
   news_coupon_user_log,
   wxamp_user_point,
   ali_oss,
   area_china,
   home_swiper,
   news_area_class,
   news_class,
   user_roles,
   user,
   wxamp,
   home,
   news_coupon,
   news_header,
   news_user_log,
   route,
   dictionary,
   file,
   home_news,
   model,
   wxamp_user,
}

